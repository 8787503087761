import React from 'react'
import Modal from '@solid-ui-components/Modal'
import SignupForm from './SignupForm'
import Divider from '@solid-ui-components/Divider'
import { Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'


const SignupModal = ({ content }) => {

  return (
    <Modal
      id={content.identifier}
      contentStyles={{ maxWidth: 500, p: 0}}
    >
      <Box sx={{ p: 4 }}>
        {content.text && (
          <>
            <Box sx={{ textAlign: `center` }}>
                <ContentText content={content.text} />
            </Box>
            <Divider space={3} />
          </>
        )}
        <SignupForm
          content={content}
        />
      </Box>
    </Modal>
  )
}

export default SignupModal