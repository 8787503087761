import React from 'react';
import { Container, Flex, Box, css } from 'theme-ui'

const styles = theme => ({

  buttonImg: {
    width: '100%',
    cursor: 'pointer',
  },
  hidden: {
    display: 'none',
  },
  disabled: {
    opacity: '.4',
    cursor: 'default',
  }
});

class ImgButton extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      hoveringButton: false,
    };
  }

  render() {
    const { classes } = this.props;

    const handleMouseOver = () => {
      if (!this.props.disabled) {
        this.setState({
          hoveringButton: true,
        });
      }
    };

    const handleMouseOut = () => {
      if (!this.props.disabled) {
        this.setState({
          hoveringButton: false,
        });
      }
    };

    const handelClick = (evt) => {
      evt.preventDefault();
      if (!this.props.disabled) {
        this.props.onClick();
      }
    };

    return (
      <a
        href={this.props.href}
        css={css({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        })}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handelClick}
      >
        <img
          alt={this.props.alt}
          title={this.props.title}
          src={this.props.defaultImgUrl}
          css={css({ 
            width: '100%',
            maxWidth: '225px',
            cursor: 'pointer',
            display: this.state.hoveringButton ? 'none' : 'block',
          })}
        />
        <img
          alt={this.props.alt}
          title={this.props.title}
          src={this.props.hoveringImgUrl}
          css={css({ 
            width: '100%',
            maxWidth: '225px',
            cursor: 'pointer',
            display: this.state.hoveringButton ? 'block' : 'none',
          })}
        />
      </a>
    )
  }
}

export default ImgButton;
